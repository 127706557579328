import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";
//import {fetchFaq,getTags} from "store/actions/FaqAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/faqPage";
import { getPrefix } from "@karpeleslab/klbfw";
//import {Link} from "react-router-dom";
//import DisplayArticle, {IMG_VARIATIONS} from "./DisplayArticle";
import { cmsFetch } from "store/actions/CmsAction";
import { useParams } from "react-router-dom";
import moment from "moment";
import Helmet from "components/instances/instance/Helmet/Helmet";
import BackPage from "components/instances/instance/BackPage/BackPage";

export const IMG_VARIATION = "strip&scale_crop=380x120&format=jpeg";
export const OG_IMG_VARIATION = "strip&scale_crop=380x120&format=jpeg";
export const COVER_IMG_VARIATION = "strip&scale_crop=380x120&format=jpeg";

const useStyles = makeStyles(styles);

const Details = ({ fetchPage, entry }) => {
  const classes = useStyles();
  const slug = useParams().article;
  const language = getPrefix()
    .split("/l/")
    .pop();

  useEffect(() => {
    fetchPage(slug);
  }, [fetchPage, slug]);

  const buildBackLink = () => {
    return <BackPage />;
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        {entry && (
          <div className={`page-wrapper`}>
            <Helmet title={`${entry.Title} | Inspire Live`}>
              <meta property="og:type" content="article" />
              <meta property="og:title" content={entry.Title} />
              {entry.Short_Contents && (
                <meta
                  property="og:description"
                  content={entry.Short_Contents}
                />
              )}
              <meta
                property="og:url"
                content={`https://inspirelive.jp${getPrefix()}/faq/${
                  entry.Content_Cms_Entry.Tags[0].Names[language]
                }/${entry.Slug}`}
              />

              <meta property="og:locale" content={entry.Language__} />
              <meta property="og:site_name" content="Inspire" />
              <meta
                property="article:published_time"
                content={moment(entry.Published.unix * 1000).format("LLL")}
              />
              {entry.Drive_Item__ && (
                <meta
                  property="og:image"
                  content={entry.Drive_Item.Media_Image[OG_IMG_VARIATION]}
                />
              )}
            </Helmet>

            {entry.Top_Drive_Item__ && (
              <div className="cms-top-img">
                <img
                  alt="cover"
                  src={entry.Top_Drive_Item.Media_Image[COVER_IMG_VARIATION]}
                />
              </div>
            )}
            <GridContainer className={classes.container} justify="center">
              <GridItem xs={12} sm={12}>
                <h6 className={classes.title}>{entry.Title}</h6>
                <div
                  className={classes.content}
                  dangerouslySetInnerHTML={{ __html: entry.Contents }}
                />
                <div style={{ textAlign: "center" }}>{buildBackLink()}</div>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    entry: state.cms.entry,
    loadingEntry: state.cms.loadingEntry,
    error: state.cms.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPage: slug => dispatch(fetchPage(slug))
  };
};

const fetchPage = slug => {
  return cmsFetch(slug, "ctcm-fnmimj-guvj-bhpi-6xmv-6u4ynrry", IMG_VARIATION);
};

Details.serverFetchPage = (match, store) =>
  store.dispatch(fetchPage(match.params.article));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Details);
