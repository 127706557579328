import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { cmsFetch } from "../../../store/actions/CmsAction";
import DisplayArticle, { IMG_VARIATIONS } from "../../cms/DisplayArticle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFound from "../NotFound/NotFound";
import { useParams } from "react-router-dom";
import "./page.scss";

const Page = ({ loadingEntry, error, entry, type, fetchPage, fetchNews }) => {
  const { slug } = useParams();

  const fetch = useCallback(
    s => {
      if (type === "page") {
        fetchPage(s).catch(err => {});
      } else {
        fetchNews(s).catch(err => {});
      }
    },
    [fetchPage, fetchNews, type]
  );

  useEffect(() => {
    fetch(slug);
  }, [fetch, slug]);

  if (loadingEntry) {
    return (
      <div className="">
        <p className="">
          <FontAwesomeIcon icon={["fas", "spinner"]} />
        </p>
      </div>
    );
  }

  if (error || !entry) return <NotFound />;

  return <DisplayArticle article={entry} type={type} />;
};

const mapStateToProps = state => {
  return {
    entry: state.cms.entry,
    loadingEntry: state.cms.loadingEntry,
    error: state.cms.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPage: slug => dispatch(fetchPage(slug)),
    fetchNews: slug => dispatch(fetchNews(slug))
  };
};

const fetchPage = slug => {
  return cmsFetch(slug, "ctcm-4gam57-gtgv-gpne-wldb-je6vrn64", IMG_VARIATIONS);
};

const fetchNews = slug => {
  return cmsFetch(slug, "ctcm-mzm3ms-ulvr-bmpg-v3h5-n3karqa4", IMG_VARIATIONS);
};

Page.serverFetchPage = (match, store) =>
  store.dispatch(fetchPage(match.params.slug));
Page.serverFetchNews = (match, store) =>
  store.dispatch(fetchNews(match.params.slug));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
