import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";
import { fetchFaq, getTags } from "store/actions/FaqAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/faqPage";
import { getPrefix } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

const Faq = ({ faqs, fetchFaq, getTags, tags }) => {
  const classes = useStyles();
  const language = getPrefix()
    .split("/l/")
    .pop();

  useEffect(() => {
    fetchFaq();
    getTags();
  }, [fetchFaq, getTags]);

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        {tags &&
          tags.map(t => (
            <GridItem xs={12} sm={6} key={t.Classify_Tag__}>
              <div className={classes.articleCategory}>
                <h5 className={classes.titleTagCategory}>
                  {t.Names[language]}
                </h5>

                {faqs &&
                  faqs.map(
                    f =>
                      f.Tags[0] &&
                      t.Classify_Tag__ === f.Tags[0].Classify_Tag__ && (
                        <div
                          key={f.Content_Cms_Entry__}
                          className={classes.questions}
                        >
                          <Link
                            to={`/faq/${t.Names[language]}/${f.Slug}`}
                            style={{ color: "unset" }}
                          >
                            {f.Title}{" "}
                          </Link>
                        </div>
                      )
                  )}
              </div>
            </GridItem>
          ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    faqs: state.faq.faq.data,
    tags: state.faq.tags.Root_Tags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFaq: () => dispatch(fetchFaq()),
    getTags: () => dispatch(getTags())
  };
};

Faq.serverFetch = (match, store) => store.dispatch(fetchFaq());

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Faq);
