import React from "react";
import styled from "styled-components";
import styles from "assets/jss/material-kit-react/components/typographyStyle.js";

import { connect } from "react-redux";
import "./custom.scss";

import HeroSlider, { MenuNav, OverlayContainer, Slide } from "hero-slider";
import { Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
// @material-ui/core components

const useStyles = makeStyles(styles);

const StyledMenuNav = styled(MenuNav)`
  &&& {
    width: 100%;
    background: #000000;
    color: yellow;
  }
`;

const StyledOverlayContainer = styled(OverlayContainer)`
  &&& {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
`;
const Hero = ({ schedule }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.sectionFirst}>
      <HeroSlider
        //slidingAnimation="top_to_bottom"
        orientation="horizontal"
        initialSlide={1}
        style={{
          color: "#FFF"
        }}
        settings={{
          slidingDuration: 750,
          slidingDelay: 250,
          shouldAutoplay: true,
          shouldDisplayButtons: false,
          autoplayDuration: 8000,
          height: "76vmin"
        }}
      >
        {schedule &&
          schedule.map((s, index) => (
            <div key={s.Planner_Schedule__}>
              {schedule &&
                s.Content_Cms_Entry_Data &&
                index < 3 &&
                s.Content_Cms_Entry_Data.Drive_Item && (
                  <Slide
                    shouldRenderMask={false}
                    navDescription={s.Label}
                    background={{
                      backgroundAnimation: "zoom",
                      backgroundColor: "#6D9B98",
                      backgroundImage:
                        s.Content_Cms_Entry_Data.Drive_Item.Media_Image.Url
                    }}
                  >
                    <StyledOverlayContainer>
                      <GridContainer
                        direction="column"
                        style={{ height: "100%" }}
                        spacing={3}
                        justify="center"
                        alignItems="center"
                      >
                        <GridItem xs={12}>
                          <Typography
                            variant="h2"
                            className={classes.titleEvent}
                          >
                            {s.Label}
                          </Typography>
                        </GridItem>
                        <GridItem xs={12}>
                          <Typography variant="subtitle2">
                            {s.Content_Cms_Entry_Data.Short_Contents}
                          </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                          <GridContainer
                            spacing={3}
                            justify="center"
                            alignItems="center"
                          >
                            <GridItem xs={6} sm={2}>
                              <Link
                                to={`/event/${s.Planner_Schedule__}`}
                                key={s.Content_Cms_Entry_Data}
                              >
                                <Button variant="contained" color="primary">
                                  {t("hero_view_btn")}
                                </Button>
                              </Link>
                            </GridItem>

                            <GridItem xs={6} sm={2}>
                              <Link
                                to={`/event/${s.Planner_Schedule__}`}
                                key={s.Content_Cms_Entry_Data}
                              >
                                <Button variant="contained" color="primary">
                                  {t("hero_reserve_ticket_btn")}
                                </Button>
                              </Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </StyledOverlayContainer>
                  </Slide>
                )}
            </div>
          ))}

        <StyledMenuNav />
      </HeroSlider>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    schedule: state.schedule.data,
    loading: state.schedule.loading,
    error: state.schedule.error,
    state: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //fetch: (year, month) => dispatch(fetchSchedule(year, month)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hero);
