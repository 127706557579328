import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Html = () => {
  const { t } = useTranslation();
  return (
    <div id="mail-html">
      <div style={{ color: "#666", marginTop: "-6px" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://inspirelive.jp/img/header_mailing.png"
            alt="InspireLive"
          />
        </div>
        <div
          style={{
            textAlign: "center",
            width: "30%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px"
          }}
        >
          <div style={{ margin: "auto", textAlign: "left" }}>
            <p>
              {t("registered_waiting_list_mail_greeting", {
                name: Get("_waitlist").Name
              })}
            </p>
            <p>
              {t("registered_waiting_list_mail_content", {
                position: Get("_waitlist").Position
              })}
            </p>
            <p>{t("registered_waiting_list_mail_footer")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Html;
