import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Html = () => {
  const { t } = useTranslation();
  const recoverUrl =
    "https://inspirelive.jp/reset-password?login=" +
    Get("_recover_user").User_Id +
    "&password_key=" +
    encodeURIComponent(Get("_user_key"));

  return (
    <div id="mail-html">
      <div style={{ color: "#666", marginTop: "-6px" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://inspirelive.jp/img/header_mailing.png"
            alt="InspireLive"
          />
        </div>
        <div
          style={{
            textAlign: "center",
            width: "30%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px"
          }}
        >
          <div style={{ margin: "auto", textAlign: "left" }}>
            <p>{t("user_password_mail_notice")}</p>

            <p>{recoverUrl}</p>

            <p>{t("user_password_mail_regards")}</p>

            <p>{t("user_password_email_footer")}</p>

            <p>
              {t("user_password_mail_date_notice", {
                date: moment().format("YYYY-MM-DD HH:mm:ss")
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Html;
