import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { useParams, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";

import { error as errorToast } from "../../store/actions/ToastAction";

// component
import Loading from "components/loading/loading";
import Cart from "./section/list_order";
import Stripe from "./section/stripe";

// style
import common from "Scss/common.module.scss";

const Order = () => {
  const slug = useParams();
  const [orderProcess] = useRest("Order/" + slug.order + ":process");
  const orderRefresh = useRestRefresh("Order/" + slug.order + ":process");
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const submitForm = token => {
    setLoading(true);
    rest("Order/" + orderProcess.data.order.Order__ + ":process", "POST", {
      method: orderProcess.data.methods.Stripe.method,
      session: orderProcess.data.methods.Stripe.session,
      cc_token: token.token.id
    })
      .then(data => {
        setLoading(false);
        cartRefresh();
        orderRefresh();
      })
      .catch(data => {
        setLoading(false);
        errorToast(data.message);
        if (data.token === "error_lock_failed") {
          setTimeout(() => {
            orderRefresh();
          }, 5000)
        }
      });
  };

  const submitFurikomi = () => {
    setLoading(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.AozoraNet.method,
      session: orderProcess.data.methods.AozoraNet.session
    })
      .then(data => {
        setLoading(false);
        cartRefresh();
        orderRefresh();
      })
      .catch(data => {
        setLoading(false);
        errorToast(data.message);
      });
  };

  const stripeError = message => {
    errorToast(message);
  };

  const buildStripeElement = () => {
    let stripePromise = null;

    if (
      !orderProcess.data.methods.Stripe.fields.cc_token.attributes.options
        .stripe_account
    ) {
      stripePromise = loadStripe(
        orderProcess.data.methods.Stripe.fields.cc_token.attributes.key
      );
    } else {
      stripePromise = loadStripe(
        orderProcess.data.methods.Stripe.fields.cc_token.attributes.key,
        {
          stripeAccount:
            orderProcess.data.methods.Stripe.fields.cc_token.attributes.options
              .stripe_account
        }
      );
    }

    return (
      <Elements stripe={stripePromise}>
        <Stripe submitForm={submitForm} stripeError={stripeError} loading={loading} />
      </Elements>
    );
  };

  const buildStripe = () => {
    if (!orderProcess.data.methods.Stripe) {
      return null;
    } else {
      return (
        <>
          <div
            className={`${common["sub-title-wrapper"]} ${
              common["sub-title-wrapper-top"]
            }`}
          >
            <h3 className={common["sub-title"]}>{t("order_card_btn")}</h3>
            <p className={common["sub-title-info"]}>
              {t("card_payment_terms_lbl")}
            </p>
          </div>
          {buildStripeElement()}
        </>
      );
    }
  };

  const buildFurikomi = () => {
    if (!orderProcess.data.methods.AozoraNet) {
      return null;
    } else {
      return (
        <>
          <div className={common["sub-title-wrapper"]}>
            <h3 className={common["sub-title"]}>{t("order_furikomi_btn")}</h3>
            <p className={common["sub-title-info"]}>
              {t("order_furikomi_lbl")}
            </p>
          </div>
          <div className={common["btn-warapper"]}>
            <button
              className={common["btn-submit"]}
              type="button"
              onClick={() => {
                submitFurikomi();
              }}
            >
              {t("order_furikomi_btn")}
            </button>
          </div>
        </>
      );
    }
  };

  const buildPayment = () => {
    if (
      orderProcess.data.order.Status !== "completed" &&
      orderProcess.data.order.Status !== "pending-initiated"
    ) {
      return (
        <>
          {buildFurikomi()}
          {buildStripe()}
        </>
      );
    }
  };

  if (orderProcess === null || loading === true) {
    return (
      <>
        <Helmet title={t("helmet_inspire_order")} />
        <h2 className={common["title"]}>{t("title_purchase")}</h2>
        <div className={common["wrapper"]}>
          <Loading />
        </div>
      </>
    );
  } else if (orderProcess.data.order.Status === "completed") {
    return (
      <>
        <Helmet title={t("helmet_inspire_order")} />
        <h2 className={common["title"]}>{t("title_order_complate")}</h2>
        <div className={common["content-inner"]}>
          <div
            className={`${common["sub-title-wrapper"]} ${
              common["sub-title-wrapper--thanks"]
            }`}
          >
            <p>{t("txt_order_complate")}</p>
          </div>

          <div className={common["btn-warapper"]}>
            <Link to="/">{t("order_comp_btn")}</Link>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Helmet title={t("helmet_inspire_order")} />
        <h2 className={common["title"]}>{t("title_purchase")}</h2>
        <div className={common["content-inner"]}>
          <div className={common["sub-title-wrapper"]}>
            <h3 className={common["sub-title"]}>{t("title_order")}</h3>
          </div>
          <Cart removeItem={null} cart={orderProcess} />
          {buildPayment()}
        </div>
      </>
    );
  }
};

export default Order;
