import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// @material-ui/core components

import Grid from "@material-ui/core/Grid";

import Form from "./Form";
import { Get } from "@karpeleslab/klbfw";

import { useTranslation } from "react-i18next";
//style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";

const useStyles = makeStyles(styles);

export default function OrderHistoryPage() {
  const classes = useStyles();
  const login = Get("login");
  const password_key = Get("password_key");

  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <Helmet title={t("helmet_inspire_password_reset_title")} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("password_reset_title")}</h2>
      </Grid>

      <Form resetKey={password_key} login={login} />
    </div>
  );
}
