import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import EventList from "./Sections/History";

import styles from "assets/jss/material-kit-react/views/common.js";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(styles);

export default function EventListPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <Helmet title={t("helmet_inspire_events_list")} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("event_list_title")}</h2>
      </Grid>

      <EventList />
    </div>
  );
}
