import React, { useEffect } from "react";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
//import styles from "assets/jss/material-kit-react/views/common.js";
import { Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fetchFutureSchedule } from "store/actions/ScheduleAction";
import { connect } from "react-redux";

import moment from "moment";
import Button from "@material-ui/core/Button";
import { FaCalendarAlt } from "react-icons/fa";

import cx from "clsx";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

export const IMG_VARIATION = "strip&scale_crop=800x250&format=jpeg";

const useStyles = makeStyles(({ spacing, palette }) => {
  const family =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Montserrat, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
  return {
    title: {
      color: "#ffffff"
    },
    card: {
      //display: 'flex',
      minWidth: 288,
      borderRadius: 12,
      boxShadow: "#000 0 0 8px -3px"
      /* '& > *:nth-child(1)': {
        marginRight: spacing(2),
      },
      '& > *:nth-child(2)': {
        flex: 'auto',
      },*/
    },
    avatar: {},
    nameEvent: {
      fontFamily: family,
      fontSize: 16,
      fontWeight: "600",
      marginBottom: 0
    },
    subheader: {
      fontFamily: family,
      fontSize: 14,
      color: palette.grey[600],
      letterSpacing: "1px",
      marginBottom: 4
    },
    value: {
      fontSize: 12,
      color: "#9b9b9c",
      textTransform: "uppercase"
    },
    date: {
      textAlign: "center"
    },
    day: {
      color: "#b4bcbd",
      fontSize: "3em",
      fontWeight: "600"
    },
    month: {
      color: "#b4bcbd",
      fontSize: "1em",
      fontWeight: "600"
    },
    img: {
      width: "100%",
      transition: "all .3s ease",
      //transition: "transform .2s",
      "&:hover, &:focus": {
        transform: "scale(1.1)"
      }
    },
    imgEvent: {
      overflow: "hidden",
      display: "block"
    },
    infoPart: {
      //padding: "0 8px",
      textAlign: "center",
      width: "100%"
    },
    bottomDelimiter: {
      borderBottom: "solid 1px #e1e1e1"
    }
  };
});

const Event = ({
  className,
  name,
  desc,
  img,
  imgRoot,
  imgName,
  day,
  month,
  time,
  idSchedule
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={cx(styles.card, className)} elevation={0}>
      <GridContainer justify="center" spacing={2}>
        <GridItem xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.imgEvent}
            component={Link}
            to={`/event/${idSchedule}`}
          >
            <img src={img} alt={imgName} className={styles.img} />
          </Box>
        </GridItem>
      </GridContainer>

      {/*  <Box className={styles.date}>
        <h3 className={styles.month}>{month}</h3>
        <p className={styles.day}>{day}</p>
        <Box display={'flex'} alignItems={'center'}>
          <span className={styles.value}> <FaCalendarAlt /> {time}</span>
        </Box>
      </Box>
*/}
      <Grid container justify="center" className={styles.bottomDelimiter}>
        <Grid item xs={12} className={styles.infoPart}>
          <Box>
            <h3 className={styles.nameEvent}>{name}</h3>
            <p>
              {" "}
              <span className={styles.value}>
                {" "}
                <FaCalendarAlt /> {month} {day}, {time}
              </span>{" "}
            </p>
            {/*  <p className={styles.subheader}>{desc}</p> */}
          </Box>
        </Grid>
      </Grid>

      <Grid container justify="center" direction="row">
        <Grid className={styles.infoPart}>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.seeMore}
          >
            <Button
              component={Link}
              to={`/event/${idSchedule}`}
              style={{ width: "100%" }}
            >
              {t("view_details_btn")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

const ListEvents = ({ schedule, fetch }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    fetch(); //all,all will display all events, if !== all, all display current month / year
  }, [fetch]);

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <h2 className={classes.title}>{t("home_upcoming_events")}</h2>
      </GridContainer>

      <Grid container justify="center" direction="row">
        {schedule.length &&
          schedule.map(s => (
            <Grid key={s.Label} item xs={12} md={4} style={{ padding: "12px" }}>
              <Event
                name={s.Label}
                key={s.Label}
                desc={s.Planner_Event.Location_Url} // define what we want to display there
                img={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item &&
                  s.Content_Cms_Entry_Data.Drive_Item.Media_Image[IMG_VARIATION]
                }
                imgRoot={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item &&
                  s.Content_Cms_Entry_Data.Drive_Item.Media_Image.Url
                }
                imgName={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item &&
                  s.Content_Cms_Entry_Data.Drive_Item.Filename
                }
                day={moment(parseInt(s.Planner_Event.Event_Date.unixms)).format(
                  "DD"
                )}
                month={moment(
                  parseInt(s.Planner_Event.Event_Date.unixms)
                ).format("MMMM")}
                time={moment(
                  parseInt(s.Planner_Event.Event_Date.unixms)
                ).format("hh:mm A")}
                idSchedule={s.Planner_Schedule__}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    schedule: state.schedule.data,
    loading: state.schedule.loading,
    error: state.schedule.error,
    state: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: () =>
      dispatch(fetchFutureSchedule({ image_variation: IMG_VARIATION }))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListEvents);
