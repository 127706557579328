import React from "react";
import Home from "views/LandingPage/LandingPage";

import Login from "views/LoginPage/LoginPage";
import About from "views/AboutPage/AboutPage";
import Account from "views/AccountPage/AccountPage";
import OrderHistory from "views/OrderHistoryPage/OrderHistoryPage";
import { updateUser } from "store/actions/UserActions";
import Page from "../views/Page/Page";
import PageComponent from "../components/pages/Page/Page";
import Faq from "views/Faq/Faq";
import FaqComponent from "../views/Faq/Sections/Faq";
import FaqDetails from "../views/Faq/Sections/Details";
import Contact from "views/Contact/Contact";
import mailRoutes from "./mailRoutes";
import EventDetails from "views/Event/Sections/Event";
import Reserve from "views/Reserve/Reserve";
import EventList from "views/EventList/EventListPage";
import PasswordForgotten from "views/PasswordForgotten/PasswordForgotten";
import PasswordReset from "views/PasswordReset/PasswordReset";
import Order from "views/order/order";

// This set of promises will waited prior running all loadData promises.
export const prerequisites = store => {
  return [
    store.dispatch(updateUser()).catch(() => {}) // ignore error
  ];
};

export default [
  {
    path: "/",
    component: Home,
    exact: true,
    type: "route"
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    type: "route"
  },
  {
    path: "/events/:year/:month",
    component: EventList,
    exact: true,
    type: "route",
    loadData: [EventList.fetchSchedule]
  },
  {
    path: "/event/:slug",
    component: EventDetails,
    loadData: [EventDetails.fetchEvent],
    type: "route"
  },
  {
    path: "/about",
    component: About,
    exact: true,
    type: "route"
  },
  {
    path: "/reserve/:event/:qty",
    component: Reserve,
    exact: true,
    type: "route"
  },
  {
    path: "/account",
    component: Account,
    exact: true,
    type: "route"
  },
  {
    path: "/order/:order",
    component: Order,
    exact: true,
    type: "route",
    loginRequired: true
  },
  {
    path: "/order_history",
    component: OrderHistory,
    exact: true,
    type: "route"
  },
  {
    path: "/page/:slug",
    components: <Page type="page" />,
    loadData: [PageComponent.serverFetchPage],
    type: "route"
  },
  {
    path: "/faq",
    component: Faq,
    exact: true,
    loadData: [FaqComponent.serverFetch],
    type: "route"
  },
  {
    path: "/faq/:category/:article",
    component: Faq,
    exact: true,
    type: "route",
    loadData: [FaqDetails.serverFetchPage]
  },
  {
    path: "/contact",
    component: Contact,
    exact: true,
    type: "route"
  },
  {
    path: "/forget-password",
    component: PasswordForgotten,
    exact: true,
    type: "route"
  },
  {
    path: "/reset-password",
    component: PasswordReset,
    exact: true,
    type: "route"
  },
  ...mailRoutes
];
