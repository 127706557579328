import React from "react";
import Helmet from "../instances/instance/Helmet/Helmet";
import { getPrefix } from "@karpeleslab/klbfw";
import moment from "moment";
import BackPage from "../instances/instance/BackPage/BackPage";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";
export const OG_IMG_VARIATION = "format=jpeg&strip&scale_crop=1200x1200";
export const MAIN_IMG_VARIATION = "format=jpeg&strip&scale_crop=1200x1200";
export const COVER_IMG_VARIATION = "format=jpeg&strip&scale_crop=1325x290";

export const IMG_VARIATIONS = [
  OG_IMG_VARIATION,
  MAIN_IMG_VARIATION,
  COVER_IMG_VARIATION
];

const useStyles = makeStyles(styles);

export default function DisplayArticle({ article, type }) {
  const classes = useStyles();

  const buildBackLink = () => {
    if (type !== "page") {
      return <BackPage />;
    }
  };

  return (
    <div className={`page-wrapper`}>
      <Helmet title={`${article.Title} | Inspire Live`}>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.Title} />
        {article.Short_Contents && (
          <meta property="og:description" content={article.Short_Contents} />
        )}
        <meta
          property="og:url"
          content={
            "https://inspirelive.jp" +
            getPrefix() +
            "/" +
            type +
            "/" +
            article.Slug
          }
        />

        <meta property="og:locale" content={article.Language__} />
        <meta property="og:site_name" content="Inspire" />
        <meta
          property="article:published_time"
          content={moment(article.Published.unix * 1000).format("LLL")}
        />
        {article.Drive_Item__ && (
          <meta
            property="og:image"
            content={article.Drive_Item.Media_Image[OG_IMG_VARIATION]}
          />
        )}
      </Helmet>

      {article.Top_Drive_Item__ && (
        <div className="cms-top-img">
          <img
            alt="cover"
            src={article.Top_Drive_Item.Media_Image[COVER_IMG_VARIATION]}
          />
        </div>
      )}
      <GridContainer className={classes.container} justify="center">
        <GridItem xs={12} sm={12}>
          <h6 className={classes.title}>{article.Title}</h6>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: article.Contents }}
          />
          {buildBackLink()}
        </GridItem>
      </GridContainer>
    </div>
  );
}
