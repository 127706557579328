import typography from "assets/jss/material-kit-react/components/typographyStyle";

const faqStyle = {
  ...typography,
  question: {
    color: "#000000",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "18px",
    cursor: "pointer"
  },
  articleCategory: {
    padding: "20px 10px",
    backgroundColor: "#fff",
    borderRadius: "1px",
    color: "#fff",
    transition: "all .4s",
    textshadow: "0 2px 4px rgba(4,27,99,0.25)",
    border: "1px solid #05d1d6",
    boxShadow: " 0 2px 3px 0 rgba(0,0,0,0.5), 0 0 25px 0 rgba(18,60,255,0.16)",
    position: "relative",
    zIndex: 2,
    marginBottom: "24px"
  },
  titleTagCategory: {
    position: "absolute",
    zIndex: "100",
    display: "inline-block",
    padding: "4px 8px",
    color: "#fff",
    background: "#05d1d6",
    marginTop: "-35px",
    marginLeft: "10px",
    textTransform: "uppercase",
    fontSize: "12px"
  },
  questions: {
    overflow: "hidden",
    //textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    margin: "12px 0",
    marginBottom: "0.3em",
    padding: "0 1.2em",
    fontWeight: "normal",
    lineHeight: "1.3em",
    color: "#05d1d6"
  }
};

export default faqStyle;
