import { rest } from "@karpeleslab/klbfw";
import { handleError } from "../../components/utils/apiErrorHandler";
import {
  FAQ_FETCH,
  FAQ_FETCH_DONE,
  TAGS_FETCH,
  TAGS_FETCH_DONE
} from "../reducers/FaqReducer";

export const fetchFaq = () => {
  return (dispatch, getState) => {
    dispatch({ type: FAQ_FETCH });
    return rest("Content/Cms/@faq:search")
      .then(data => {
        dispatch({ type: FAQ_FETCH_DONE, faq: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const getTags = () => {
  return (dispatch, getState) => {
    dispatch({ type: TAGS_FETCH });
    return rest("Classify/clsf-cflyrs-yzqn-djrn-a7ik-oqd3cz4m", "GET")
      .then(data => {
        dispatch({ type: TAGS_FETCH_DONE, tags: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
