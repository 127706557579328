import {
  FETCH_SCHEDULE_BEGIN,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_FUTURE_SCHEDULE_BEGIN,
  FETCH_FUTURE_SCHEDULE_SUCCESS,
  EVENT_FETCH,
  EVENT_FETCH_DONE,
  EVENTS_SET_PAGING
} from "../reducers/ScheduleReducer";

import { rest } from "@karpeleslab/klbfw";

import moment from "moment";
import { handleError } from "../../components/utils/apiErrorHandler";

export const fetchSchedule = (year, month, variationImg) => {
  if (year === "undefined" || !year) {
    year = moment().year();
  }

  if (month === "undefined" || !month) {
    month = moment().month() + 1;
  }

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SCHEDULE_BEGIN });

    const params = {
      ...getState().schedule.eventsPaging,
      sort: {
        Date: "ASC"
      },
      image_variation: variationImg,
      Visible: "Y"
    };

    //allow to display all upcoming event without filters in homepage
    if (year !== "all" && month !== "all") {
      params.year = year;
      params.month = month;
    }

    return rest("Planner/@main/Schedule", "GET", params)
      .then(m => {
        dispatch({
          type: FETCH_SCHEDULE_SUCCESS,
          schedule: m.data,
          paging: m.paging
        });
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const fetchFutureSchedule = variationImg => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_FUTURE_SCHEDULE_BEGIN });

    const params = {
      sort: {
        Date: "ASC"
      },
      image_variation: variationImg,
      Date: { $gt: "now" }, // yes we can do that
      Visible: "Y"
    };

    return rest("Planner/@main/Schedule", "GET", params)
      .then(m => {
        dispatch({
          type: FETCH_FUTURE_SCHEDULE_SUCCESS,
          schedule: m.data,
          paging: m.paging
        });
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const fetchEvent = (slug, variationImg) => {
  return (dispatch, getState) => {
    dispatch({ type: EVENT_FETCH });

    const params = {
      image_variation: variationImg
    };

    return rest("Planner/Schedule/" + slug, "GET", params)
      .then(data => {
        dispatch({ type: EVENT_FETCH_DONE, event: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const setEventsPaging = newPaging => {
  return (dispatch, getState) => {
    dispatch({
      type: EVENTS_SET_PAGING,
      paging: { ...getState().schedule.eventsPaging, ...newPaging }
    });
  };
};
