import {
  CONTACT_ERROR,
  CONTACT_RESET,
  CONTACT_SENDING,
  CONTACT_SENT
} from "../reducers/ContactReducer";
import { rest } from "@karpeleslab/klbfw";

export const contact = (
  email,
  name,
  to,
  subject,
  message,
  tag,
  attach = []
) => {
  return (dispatch, getState) => {
    let params = {
      Email: email,
      Name: name,
      To: to,
      Subject: subject,
      Message: message,
      Tags: [tag]
    };

    if (attach.length > 0) params.Attach = attach;

    dispatch({ type: CONTACT_SENDING });
    return rest("Support/Ticket", "POST", params)
      .then(() => {
        dispatch({ type: CONTACT_SENT });
        setTimeout(() => {
          dispatch({ type: CONTACT_RESET });
        }, 10000);
      })
      .catch(e => {
        dispatch({ type: CONTACT_ERROR, error: e.message });
        throw e;
      });
  };
};
