import React from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.js";

const BackPage = props => {
  const { t } = useTranslation();
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="">
      <Button
        color="primary"
        variant="contained"
        className=""
        onClick={() => goBack()}
      >
        <span className="">{t("back_previous_page_btn")}</span>
      </Button>
    </div>
  );
};

export default withRouter(BackPage);
