import React from "react";
import { Get } from "@karpeleslab/klbfw";
import moment from "moment";
import { Logo } from "./PdfImage.js";
import { useTranslation } from "react-i18next";

const Pdf = () => {
  const { t } = useTranslation();

  const quantity = line => {
    if (line.Quantity) {
      return line.Quantity;
    } else {
      return "1";
    }
  };

  const period = line => {
    if (line.Period_End) {
      return (
        <span
          style={{
            fontSize: "10pt",
            fontStyle: "italic"
          }}
        >
          <br />
          {moment(line.Period_Start?.unixms).format(
            "YYYY-MM-DD HH:mm:ss"
          )}{" "}
          {moment(line.Period_End?.unixms).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      );
    }
  };

  const buildStatus = status => {
    if (status === "deliver-done") {
      return t("status_deliver_done");
    } else {
      return status;
    }
  };

  const items = () => {
    return Get("_order").Items.map(line => {
      return (
        <tr>
          <td
            style={{
              border: "1px solid #9fa5a7"
            }}
          >
            {line.Catalog_Product["Basic.Name"]}
            {period(line)}
          </td>
          <td
            style={{
              border: "1px solid #9fa5a7"
            }}
          >
            {buildStatus(line.Status)}
          </td>
          <td
            style={{
              border: "1px solid #9fa5a7",
              textAlign: "right",
              whiteSpace: "nowrap"
            }}
          >
            {line.Price.display}
          </td>
          <td
            style={{
              border: "1px solid #9fa5a7",
              textAlign: "center"
            }}
          >
            {quantity(line)}
          </td>
          <td
            style={{
              border: "1px solid #9fa5a7",
              textAlign: "right",
              whiteSpace: "nowrap"
            }}
          >
            {line.Full_Price.display}
          </td>
        </tr>
      );
    });
  };

  const paymentMethod = () => {
    if (Get("_order").Payment_Method !== "") {
      return (
        <div
          id="bill_paiment"
          style={{
            width: "100%",
            margin: "20px 0"
          }}
        >
          {t("payment_method")}: {Get("_order").Payment_Method}
          <br />
          {t("payment_date")}:{" "}
          {moment(Get("_order").Paid?.unixms).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      );
    }
  };

  const orderStatus = () => {
    if (Get("_order").Status === "completed") {
      return t("status_deliver_completed");
    } else {
      return Get("_order").Status;
    }
  };

  return (
    <div
      style={{
        fontFamily: "Arial",
        fontSize: "12px"
      }}
    >
      <div
        style={{
          marginTop: "3%",
          width: "100%",
          textAlign: "center",
          background: "#707070",
          padding: "20px",
          marginBottom: "20px"
        }}
      >
        <img src={Logo} width="130px" alt="InspireLive" />
      </div>
      <div
        style={{
          marginBottom: "20px"
        }}
      >
        <div
          style={{
            marginLeft: "500px",
            border: "1px solid #aaa",
            padding: "5px"
          }}
        >
          {Get("_order").Billing_User_Location.Last_Name}{" "}
          {Get("_order").Billing_User_Location.First_Name}
          <br />
          {Get("_order").Billing_User_Location.Zip}
        </div>
        <div
          style={{
            width: "100%",
            margin: "auto"
          }}
        >
          {t("order_number")} : {Get("_order").Invoice_Number}
          <br />
          {t("order_status")} : {orderStatus()}
        </div>
        <div>&nbsp;</div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse"
          }}
        >
          <tbody>
            <tr>
              <th
                style={{
                  background: "#e8f6fc",
                  border: "1px solid #9fa5a7",
                  width: "465px",
                  textAlign: "center"
                }}
              >
                {t("product_name")}
              </th>
              <th
                style={{
                  background: "#e8f6fc",
                  border: "1px solid #9fa5a7",
                  width: "75px",
                  textAlign: "center"
                }}
              >
                {t("product_status")}
              </th>
              <th
                style={{
                  background: "#e8f6fc",
                  border: "1px solid #9fa5a7",
                  width: "75px",
                  textAlign: "center"
                }}
              >
                {t("price")}
              </th>
              <th
                style={{
                  background: "#e8f6fc",
                  border: "1px solid #9fa5a7",
                  width: "50px",
                  textAlign: "center"
                }}
              >
                {t("quantity")}
              </th>
              <th
                style={{
                  background: "#e8f6fc",
                  border: "1px solid #9fa5a7",
                  width: "75px",
                  textAlign: "center"
                }}
              >
                {t("sub_total")}
              </th>
            </tr>
            {items()}
            <tr>
              <td colSpan="5">&nbsp;</td>
            </tr>
            <tr>
              <td colSpan="4" align="right">
                {t("sub_total")}
              </td>
              <td style={{ textAlign: "right" }}>
                {Get("_order").Total.display}
              </td>
            </tr>
            <tr>
              <td colSpan="4" align="right">
                {t("vat")} ({Get("_order").Vat_Rate}%)
              </td>
              <td style={{ textAlign: "right" }}>
                {Get("_order").Vat_Amount.display}
              </td>
            </tr>
            <tr>
              <td colSpan="4" align="right">
                {t("total")}
              </td>
              <td style={{ textAlign: "right" }}>
                {Get("_order").Total_Vat.display}
              </td>
            </tr>
          </tbody>
        </table>
        {paymentMethod()}
      </div>

      <div
        style={{
          textAlign: "center",
          borderTop: "1px solid black"
        }}
      >
        https://inspirelive.jp/
      </div>
    </div>
  );
};

export default Pdf;
