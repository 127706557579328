import React, { useState, useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/eventPage";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

import sassStyles from "../../../Scss/stream.module.scss";

const useStyles = makeStyles(styles);

const StreamContainer = ({ schedule }) => {
  const classes = useStyles();
  const [overLayShow, setOverLayShow] = useState(true);
  const [streamInfo, setStreamInfo] = useState();
  const { t } = useTranslation();

  const updatePlanner = state => {
    rest("Planner/Event/" + schedule.Planner_Event__ + ":stream")
      .then(res => {
        if (!state.enabled) return;

        setStreamInfo(res.data);
        let t = res.data.Hls_Recheck_Delay;
        if (t < 5) t = 5;
        state.timeout = setTimeout(() => updatePlanner(state), t * 1000);
      })
      .catch(() => {
        if (!state.enabled) return;
        state.timeout = setTimeout(() => updatePlanner(state), 60000);
      });
  };

  useEffect(() => {
    let state = { enabled: true };
    updatePlanner(state);

    return () => {
      state.enabled = false;
      clearTimeout(state.timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideOverLay = () => {
    setOverLayShow(false);
  };

  const buildOverLay = () => {
    if (overLayShow) {
      return (
        <div
          onClick={() => {
            hideOverLay();
          }}
          className={sassStyles["stream-overLay"]}
        >
          <span>{t("stream_mute_btn")}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  if (!streamInfo) return null;
  if (streamInfo.Hls_Url === "") return <Grid></Grid>;

  return (
    <Grid
      className={`${classes.stream} ${sassStyles["stream"]}`}
      style={{ marginBottom: "24px" }}
    >
      {buildOverLay()}
      <ReactPlayer
        controls={true}
        url={streamInfo.Hls_Url}
        width={"100%"}
        muted={overLayShow}
        playing={true}
        playsinline={true}
      />
    </Grid>
  );
};

export default StreamContainer;
