import React from "react";
import SetStatus from "../../Route/SetStatus";
import NotContent from "components/notcontent/notcontent";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  notFound: {
    textAlign: "center",
    padding: "12px"
  }
}));

const NotFound = props => {
  const classes = useStyles();
  return (
    <SetStatus statusCode={404}>
      <div className={classes.notFound}>
        <NotContent />
      </div>
    </SetStatus>
  );
};

export default NotFound;
