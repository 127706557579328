import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";
// core components
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Faq from "./Sections/Faq";
import Details from "./Sections/Details";

const useStyles = makeStyles(styles);
export default function FAQ() {
  const classes = useStyles();
  const { t } = useTranslation();
  const slug = useParams();

  return (
    <div>
      <Helmet title={t("helmet_inspire_faq")} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("faq_title")}</h2>
      </Grid>

      <Grid>
        <GridItem xs={12} sm={12} style={{ padding: "16px" }}>
          <Grid container spacing={3} justify="center">
            <GridItem xs={10} md={8} lg={8}>
              {slug.category === undefined && slug.article === undefined && (
                <Faq />
              )}
              {slug.category !== undefined && slug.article !== undefined && (
                <Details />
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
}
