import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import styles from "assets/jss/material-kit-react/views/page.js";

// Sections for this page
import Page from "components/pages/Page/Page";
import { useTranslation } from "react-i18next";

//const useStyles = makeStyles(styles);

export default function LandingPage() {
  //const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Helmet title={t("helmet_inspire_page")} />

      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <Page type={"page"} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
