import React from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const NotContent = props => {
  const { t } = useTranslation();
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="">
      <p>
        {t("no_content_no_content_p")} <br />{" "}
        {t("no_content_return_previous_page")}
      </p>
      <button onClick={goBack} className="">
        {t("back_previous_page_btn")}
      </button>
    </div>
  );
};

export default withRouter(NotContent);
