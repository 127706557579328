import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

// @material-ui/core components
// core components

import { useTranslation } from "react-i18next";

import FormContact from "./FormContact";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";
const useStyles = makeStyles(styles);

export default function Contact() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Helmet title={t("helmet_inspire_contact")} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("contact_title")}</h2>
      </Grid>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} style={{ padding: "16px" }}>
          <GridContainer spacing={3} justify="center" alignItems="center">
            <GridItem xs={10} md={8} lg={8}>
              <FormContact />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
