import { rest } from "@karpeleslab/klbfw";

import {
  SYNC_CMS,
  SYNC_CMS_DONE,
  SYNC_CMS_ERROR,
  SYNC_CMS_LIST,
  SYNC_CMS_LIST_DONE,
  SYNC_CMS_LIST_ERROR
} from "../reducers/CmsReducer";

export const cmsFetch = (slug, cms, imgVariation = null) => {
  const query = {
    base: "page",
    path: slug,
    class: "Content/Cms",
    id: cms,
    image_variation: imgVariation
  };

  return (dispatch, getState) => {
    dispatch({ type: SYNC_CMS });

    return rest("Misc/HandleQuery:process", "GET", query)
      .then(data => {
        dispatch({
          type: SYNC_CMS_DONE,
          entry: data.data.Vars._cms_entry_data
        });
      })
      .catch(data => {
        dispatch({ type: SYNC_CMS_ERROR, error: data.message });
        throw data;
      });
  };
};

export const list = (cms, paging, filters = {}, imgVariation = null) => {
  return (dispatch, getState) => {
    dispatch({ type: SYNC_CMS_LIST });

    paging = { ...getState().cms.paging, ...paging };

    const query = {
      ...paging,
      sort: "published:desc",
      image_variation: imgVariation
    };

    if (Object.entries(filters).length) query.query = filters;

    return rest("Content/Cms/" + cms + ":search", "POST", query)
      .then(data => {
        dispatch({
          type: SYNC_CMS_LIST_DONE,
          list: data.data.data,
          paging: data.paging
        });
      })
      .catch(data => {
        dispatch({ type: SYNC_CMS_LIST_ERROR, error: data.message });
        throw data;
      });
  };
};
